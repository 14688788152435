import React from 'react';
import Container from '@mui/material/Container';

import grey from '@mui/material/colors/grey';
import orange from '@mui/material/colors/orange';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: orange,
    secondary: grey,
  },
});

function LoginCallback() {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={false} disableGutters sx={{ overflowX: 'hidden' }}>
      </Container>
    </ThemeProvider>
  );
}

export default LoginCallback;
