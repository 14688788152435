import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Grid } from '@mui/material';

function AMRPredictionRow(props) {
  const {
    prediction,
    isDiagnosticView,
    showImputedCalls
  } = props;

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const [imputations, setImputations] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!prediction || isDiagnosticView || !showImputedCalls) {
      return;
    }

    getAccessTokenSilently()
      .then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/amr_model_predictions/${prediction.id}/imputations`,
            {
              method: 'GET',
              headers,
            },
          )
      })
      .then((res) => res.json())
      .then((response) => {
        if (!response.data.length) {
          setImputations(null);
          return;
        }

        setImputations(response.data)
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, prediction, isDiagnosticView, showImputedCalls]);

  let callColor = '#0081ff';
  let callText = 'Intermediate';
  if (prediction.attributes.amr_call === 'R') {
    callColor = '#ff9800';
    callText = 'Resistant';
  } else if (prediction.attributes.amr_call === 'S') {
    callColor = 'green';
    callText = 'Susceptible';
  }

  let confidenceContent = null;
  if (!isDiagnosticView) {
    const confidenceColor = prediction.attributes.model_performance_label === 'M'
          ? 'grey' : 'grey';

    const confidenceText = prediction.attributes.model_performance_label === 'M'
      ? 'R&D Model' : 'Qualified Model';

    confidenceContent = (
      <Grid item xs={12} sm={5}>
        <p>
          <b><span style={{ color: confidenceColor }}>{confidenceText}</span></b>
        </p>
      </Grid>
    );
  }

  let imputedContent = null;
    if (!isDiagnosticView && showImputedCalls && imputations) {
        imputedContent = imputations.map((imputation, idx) => {
          let callText = 'Intermediate';
          if (imputation.attributes.call === 'R') {
            callText = 'Resistant';
          } else if (imputation.attributes.call === 'S') {
            callText = 'Susceptible';
          }

            return (
                <Grid
                  container
                  sx={{
                      ml:3, pl: 5, textAlign: 'center', color: '#4f4f4f', backgroundColor: '#ddd', borderTop: '#bdbdbd 2px solid', borderLeft: '#bdbdbd 2px solid', borderRight: '#bdbdbd 2px solid'
                  }}
                >
                  <Grid item xs={12} sm={4} sx={{ textAlign: 'left' }}>
                    <p style={{marginTop: 7, marginBottom: 7}}><b><small>{imputation.attributes.drug}</small></b></p>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <p style={{marginTop: 7, marginBottom: 7, paddingLeft: 10}}><b><small>{callText}</small></b></p>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <p style={{marginTop: 7, marginBottom: 7}}><b><small>Imputed</small></b></p>
                  </Grid>
                </Grid>
            )    
        })
    }

  return (
    <Grid
      container
      sx={{
        pl: 3, pr: 1, textAlign: 'center', borderBottom: '#bdbdbd 2px solid', borderLeft: '#bdbdbd 2px solid',
      }}
    >
      <Grid item xs={12} sm={isDiagnosticView ? 10 : 5} sx={{ textAlign: 'left' }}>
        <p><b>{prediction.attributes.model_drug}</b></p>
      </Grid>
      <Grid item xs={12} sm={2}>
        <b><p style={{ color: callColor }}>{callText}</p></b>
      </Grid>
      {confidenceContent}
      {imputedContent}
    </Grid>
  );
}

export default AMRPredictionRow;
