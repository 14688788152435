import React from 'react';
import { Link } from 'react-router-dom';

import { Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function BackButton(props) {
  const {
    backToLink,
    backButtonText,
  } = props;

  return (
    <Link to={backToLink}>
      <Grid container alignItems="center" sx={{ color: '#ff9800' }}>
        <Grid item xs={1} sx={{ mt: '2px' }}>
          <ArrowBackIcon />
        </Grid>
        <Grid item xs={11} sx={{ pl: 2, fontWeight: 'bold', fontSize: '0.8em' }}>
          {backButtonText}
        </Grid>
      </Grid>
    </Link>
  );
}

export default BackButton;
