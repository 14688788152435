import React, {useState} from 'react';

import Box from '@mui/material/Box';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import { PieChart  } from '@mui/x-charts/PieChart';

function SequencingYieldPieChart(props) {
    const {
        analysisAttributes
    } = props;

    const [view, setView] = useState('megabases')

    const analysis = {
        attributes: analysisAttributes
    }

      const isIllumina = analysis.attributes.raw_total_megabases_r2 !== null
      const qcFilteredMegabases = isIllumina ?
            ((analysis.attributes.raw_total_megabases + analysis.attributes.raw_total_megabases_r2) - (analysis.attributes.qc_filtered_megabases + analysis.attributes.qc_filtered_megabases_r2)) :
            (analysis.attributes.downsampled_megabases - analysis.attributes.qc_filtered_megabases)

      const qcFilteredReads = isIllumina ?
            (analysis.attributes.raw_total_reads - analysis.attributes.qc_filtered_reads) :
            (analysis.attributes.downsampled_reads - analysis.attributes.qc_filtered_reads)

      const humanReadsRemovedMegabases = isIllumina ?
            ((analysis.attributes.downsampled_megabases + analysis.attributes.downsampled_megabases_r2) - (analysis.attributes.human_reads_removed_megabases + analysis.attributes.human_reads_removed_megabases_r2)) :
            (analysis.attributes.qc_filtered_megabases - analysis.attributes.human_reads_removed_megabases)

      const humanReadsRemovedReads = isIllumina ?
            (analysis.attributes.downsampled_reads - analysis.attributes.human_reads_removed_reads) :
            (analysis.attributes.qc_filtered_reads - analysis.attributes.human_reads_removed_reads)

    const analyzed = view === 'megabases' ? 
        (isIllumina ? (analysisAttributes.human_reads_removed_megabases + analysisAttributes.human_reads_removed_megabases_r2) : analysisAttributes.human_reads_removed_megabases) : 
        analysisAttributes.human_reads_removed_reads

    const lowQuality = view === 'megabases' ? 
        qcFilteredMegabases :
        qcFilteredReads

    const human = view === 'megabases' ?
        humanReadsRemovedMegabases :
        humanReadsRemovedReads

    return (
      <Box sx={{textAlign: "right"}}>
          <ToggleButtonGroup sx={{marginTop: "20px"}} size="small" value={view} exclusive onChange={(event, newView) => setView(newView)} aria-label="megabases reads toggle">
              <ToggleButton value="megabases" aria-label="megabases">
                Bases
              </ToggleButton>
              <ToggleButton value="reads" aria-label="reads">
                Reads
              </ToggleButton>
          </ToggleButtonGroup>
          <PieChart sx={{cursor: 'pointer', overflow: 'visible'}} height={200} series={[
              {
                  valueFormatter: (item) => `${item.value.toLocaleString()} ${view === 'megabases' ? 'MBs' : `read${isIllumina ? ' pairs' : 's'}`}`,
                  data: [
                    { id: 0, value: analyzed, label: 'Analyzed Data', color: 'green' },
                    { id: 2, value: lowQuality, label: 'Low Quality', color: '#FF5326' },
                    { id: 1, value: human, label: 'Human', color: '#5267AA' },
                  ],
                  highlightScope: { fade: 'global', highlight: 'item' },
                  faded: {innerRadius: 30, additionalRadius: -30, color: 'gray'}
              }
          ]} />
      </Box>
    )
}

export default SequencingYieldPieChart;
