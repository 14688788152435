import React from 'react';
import { Box, Grid } from '@mui/material';

function DiagnosticSampleMetadataSummary(props) {
  const {
    sample,
  } = props;

  return (
    <>
      <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
        <Grid container>
          <Grid item xs={12}>
            <p><b>SAMPLE</b></p>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: '1px 10px' }}>
        <p>
          <b>Type:</b>
          {' '}
          {sample?.attributes.sample_type}
        </p>
        <p>
          <b>Run Status:</b>
          {' '}
          Complete
        </p>
        <p>
          <b>Internal control:</b>
          {' '}
          N/A
        </p>
      </Box>
    </>
  );
}

export default DiagnosticSampleMetadataSummary;
