import React from 'react';
import { Box } from '@mui/material';

function TestMetadataSummary(props) {
  const {
    run,
    sample,
    isDiagnosticView,
  } = props;

  return (
    <>
      <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
        <p><b>{isDiagnosticView ? 'DIAGNOSTIC TEST' : 'SEQUENCING RUN'}</b></p>
      </Box>
      <Box sx={{ padding: '1px 10px' }}>
        <p>
          <b>Date:</b>
          {' '}
          {run ? new Date(run.attributes.created_at).toDateString() : null}
        </p>
        <p>
          <b>Sample ID:</b>
          {' '}
          {sample?.attributes.sample_name || sample?.attirbutes.sample_id || 'N/A'}
        </p>
        <p>&nbsp;</p>
      </Box>
    </>
  );
}

export default TestMetadataSummary;
