import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import RequiredAuth from './RequiredAuth';
import Layout from './Layout';
import Project from './Project';
import Sample from './Sample';
import LoginCallback from './LoginCallback'

function App() {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  }

  return (
    <div>
      <Auth0Provider
        domain="dayzerodiagnostics.us.auth0.com"
        clientId="0r5DKIPfYO0iky7cqBCK8Y7sgBycEdC9"
        authorizationParams={{
          redirect_uri: `${window.location.origin}/callback`,
          scope: [
            'profile',
            'dzd.keynome.organizations.view',
            'dzd.keynome.projects.manage',
            'dzd.keynome.projects.view',
            'dzd.keynome.tests.manage',
            'dzd.keynome.runs.view',
            'dzd.keynome.runs.manage',
            'dzd.keynome.lib_seqs.view',
            'dzd.keynome.lib_seqs.manage',
            'dzd.keynome.concatenated_fastqgzs.view',
            'dzd.keynome.concatenated_fastqgzs.manage',
            'dzd.keynome.analysis_executions.view',
            'dzd.keynome.analyses.view',
            'dzd.keynome.tests.view',
            'dzd.keynome.identified_species.view',
            'dzd.keynome.amr_model_predictions.view',
            'dzd.keynome.imputations.view',
            'dzd.keynome.users.view',
          ].join(" "),
          audience: "https://keynome-api"
        }}
        onRedirectCallback={onRedirectCallback}
      >
        <Routes>
          <Route path="/callback" element={<LoginCallback />} />
          <Route path="/" element={<RequiredAuth />}>
            <Route path="" element={<Layout />}>
              <Route path="" element={<Project />} />
              <Route path="projects/:projectId/runs/:runId/samples/:sampleId" element={<Sample />} />
            </Route>
          </Route>
        </Routes>
      </Auth0Provider>
    </div>
  );
}

export default App;
