import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import AMRPredictionRow from '../AMRPredictions/AMRPredictionRow';

function IdentifiedSpeciesRow(props) {
  const {
    isIllumina,
    identifiedSpecies,
    isDiagnosticView,
    showAST,
    showKIDCalls,
    showRnDModels,
    showImputedCalls,
    showAlignmentMetrics,
      showCoverageBreadth,
      showCoverageDepth,
      showAssemblyMetrics,
      showAssemblyMBsToSpecies,
      showContigsToSpecies,
      q20Rate,
    assemblyN50,
    assemblyCoverageLT2xDepth
  } = props;
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const [amrPredictions, setAmrPredictions] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!identifiedSpecies || (!identifiedSpecies.attributes.species_call_positive && !isIllumina)) {
      return;
    }

    getAccessTokenSilently()
      .then(accessToken => {
        const headers = new Headers({
          Authorization: `Bearer ${accessToken}`,
        });

        return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/identified_species/${identifiedSpecies.id}/amr_model_predictions`,
            {
              method: 'GET',
              headers,
            },
          )
      })
      .then((res) => res.json())
      .then((response) => {
        if (!response.data.length) {
          setAmrPredictions(null);
          return;
        }

        const highConfidenceAmrPredictions = response.data
          .filter((item) => {
              let performances = ['H', 'VH']
              if (showRnDModels) {
                performances.push('M')
              }

              return performances.includes(item.attributes.model_performance_label)
          }).sort((a, b) => a.attributes.model_drug.localeCompare(b.attributes.model_drug));

        setAmrPredictions(highConfidenceAmrPredictions);
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, getAccessTokenSilently, identifiedSpecies, showRnDModels, isIllumina]);

  let amrPredictionsContent = null;
  if ((showAST || isDiagnosticView) && (identifiedSpecies.attributes.species_call_positive || isIllumina) && amrPredictions && amrPredictions.length) {
    amrPredictionsContent = amrPredictions.map(
      (item) => <AMRPredictionRow prediction={item} isDiagnosticView={isDiagnosticView} showImputedCalls={showImputedCalls} />,
    );
  }

  const speciesName = `${identifiedSpecies.attributes.species.replace('[', '').split(' ')[0][0]}. ${identifiedSpecies.attributes.species.split(' ')[1]}`;
  const speciesNameContent = (identifiedSpecies.attributes.species_call_positive || (isIllumina && identifiedSpecies.attributes.reads_frac_agreeing_megabases >= 0.8))
    ? (<p style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow:'ellipsis'}}><b>{speciesName}</b></p>)
    : (<p style={{ fontWeight: (showKIDCalls || isIllumina) ? null : 'bold', color: (showKIDCalls || isIllumina) ? 'gray' : null, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow:'ellipsis' }}>{speciesName}</p>);

  let positivityContent = null;
  if (!isDiagnosticView && showKIDCalls && !isIllumina) {
    positivityContent = (
      <Grid item xs={12} sm={2}>
        {identifiedSpecies.attributes.species_call_positive
      ? (<p style={{ color: 'green' }}><b>Positive </b></p>)
      : (
        <p style={{ color: 'gray' }}>
          Subthreshold
        </p>
      )}
      </Grid>
    );
  }


  const detailContentColor = (identifiedSpecies.attributes.species_call_positive || (!showKIDCalls && !isIllumina) || (isIllumina && identifiedSpecies.attributes.reads_frac_agreeing_megabases >= 0.8)) ? null : 'gray';

  let kidValueContent = null;
  if (!isDiagnosticView && !isIllumina) {
    kidValueContent = (
      <Grid item xs={12} sm={2}>
        <p style={{ color: detailContentColor, marginTop: '5px', marginBottom: '5px' }}><small><b>
          {identifiedSpecies.attributes.species_call_raw_value < 0.001 ? '<0.001' : Math.min(identifiedSpecies.attributes.species_call_raw_value, 1.000).toFixed(3)}
            <br /> KID value
        </b></small></p>
      </Grid>
    );
  }

  let readsToSpeciesContent = null;
  if (!isDiagnosticView && isIllumina) {
    readsToSpeciesContent = (
      <Grid item xs={12} sm={2}>
        <p style={{ color: detailContentColor, marginTop: '5px', marginBottom: '5px' }}><small>
          <b>{identifiedSpecies.attributes.reads_to_species ? identifiedSpecies.attributes.reads_to_species.toLocaleString() : '-'} read pairs</b>
            <br />
          matching organism
        </small></p>
      </Grid>
    );
  }

  let fractionMegabasesToGenusContent = null;
  if (!isDiagnosticView && isIllumina && false) {
    fractionMegabasesToGenusContent = (
      <Grid item xs={12} sm={2}>
        <p style={{ color: detailContentColor, marginTop: '5px', marginBottom: '5px' }}><small>
          <b>{identifiedSpecies.attributes.reads_frac_agreeing_megabases ? (identifiedSpecies.attributes.reads_frac_agreeing_megabases * 100).toFixed(2) : '-'}%</b>
            <br />
          matching genus
        </small></p>
      </Grid>
    );
  }

  let megabasesToSpeciesContent = null;
  if (!isDiagnosticView) {
    megabasesToSpeciesContent = (
      <Grid item xs={12} sm={2}>
        <p style={{ color: detailContentColor, marginTop: '5px', marginBottom: '5px' }}><small>
          <b>{identifiedSpecies.attributes.reads_megabases_to_species ? identifiedSpecies.attributes.reads_megabases_to_species.toFixed(2) : '-'} MBs from reads</b>
            <br />
          matching organism
        </small></p>
      </Grid>
    );
  }

  let coverageBreadthContent = null;
  if (!isDiagnosticView && showAlignmentMetrics && showCoverageBreadth && !isIllumina) {
    coverageBreadthContent = (
      <Grid item xs={12} sm={2}>
        <p style={{ color: detailContentColor, marginTop: '5px', marginBottom: '5px' }}><small>
          {identifiedSpecies.attributes.perc_nonzero_coverage ? (identifiedSpecies.attributes.perc_nonzero_coverage * 100).toFixed(2) : '-'}
          %<br/>reference coverage</small>
        </p>
      </Grid>
    );
  }

  let coverageDepthContent = null;
  if (!isDiagnosticView && showAlignmentMetrics && showCoverageDepth && !isIllumina) {
    coverageDepthContent = (
      <Grid item xs={12} sm={2}>
        <p style={{ color: detailContentColor, marginTop: '5px', marginBottom: '5px' }}><small>
          {identifiedSpecies.attributes.avg_depth ? (identifiedSpecies.attributes.avg_depth).toFixed(2) : '-'}
          <br/>average depth</small>
        </p>
      </Grid>
    );
  }

  let assemblyMegabasesToSpeciesContent = null;
  if (!isDiagnosticView && showAssemblyMetrics && showAssemblyMBsToSpecies && isIllumina) {
    assemblyMegabasesToSpeciesContent = (
      <Grid item xs={12} sm={3}>
        <p style={{ color: detailContentColor, marginTop: '5px', marginBottom: '5px' }}><small>
          {identifiedSpecies.attributes.assembly_megabases_to_species ? (identifiedSpecies.attributes.assembly_megabases_to_species).toLocaleString() : '-'} MBs from raw assembly
          <br/>matching organism</small>
        </p>
      </Grid>
    );
  }

  let assemblyContigsToSpeciesContent = null;
  if (!isDiagnosticView && showAssemblyMetrics && showContigsToSpecies && isIllumina) {
    assemblyContigsToSpeciesContent = (
      <Grid item xs={12} sm={3}>
        <p style={{ color: detailContentColor, marginTop: '5px', marginBottom: '5px' }}><small>
          {identifiedSpecies.attributes.contigs_to_species ? (identifiedSpecies.attributes.contigs_to_species).toLocaleString() : '-'} contigs from raw assembly
          <br/>matching organism</small>
        </p>
      </Grid>
    );
  }

  let qualityLimitations = []
    if (!isDiagnosticView && amrPredictionsContent) {
        if (q20Rate <= 0.70) {
            qualityLimitations.push('low quality sequencing data')
        }

        if (
            !isIllumina &&
            (((identifiedSpecies.attributes.assembly_n50 || assemblyN50) <= 6000) ||
            ((1 - (identifiedSpecies.attributes.assembly_coverage_lt_2x_depth || assemblyCoverageLT2xDepth)) <= 0.95))
        ) {
            qualityLimitations.push('low quality de novo assembly')
        }

        if (
            !isIllumina &&
            (identifiedSpecies.attributes.species_call_raw_value <= 0.6)
        ) {
            qualityLimitations.push('insufficient genomic recovery')
        }

        if (identifiedSpecies.attributes.assembly_frac_agreeing_mbs <= 0.75) {
            qualityLimitations.push('possible data contamination')
        }
    }

  return (
    <Box>
      <Grid
        container
        sx={{
          pl: 1,
          pr: 1,
          textAlign: 'center',
          borderBottom: '#bdbdbd 2px solid'
        }}
      >
        <Grid item xs={12} sm={2} sx={{ textAlign: 'left' }}>
          {speciesNameContent}
        </Grid>
        {positivityContent}
        {kidValueContent}
        {readsToSpeciesContent}
        {megabasesToSpeciesContent}
        {fractionMegabasesToGenusContent}
        {coverageBreadthContent}
        {coverageDepthContent}
        {assemblyMegabasesToSpeciesContent}
        {assemblyContigsToSpeciesContent}
      </Grid>
      {qualityLimitations.length ? (
          <Box sx={{ textAlign: 'left', backgroundColor: '#eee' }}>
            <Alert variant="filled" severity="warning" sx={{borderRadius: 0}}><b>The following <i>g</i>AST predictions are flagged as high risk for error due to quality limitations:</b> {qualityLimitations.join(', ')}</Alert>
          </Box>
    ) : null}
      <Box sx={{ pl: 10, textAlign: 'center', backgroundColor: '#eee' }}>
        {amrPredictionsContent}
      </Box>
    </Box>
  );
}

export default IdentifiedSpeciesRow;
