import React from 'react';
import { Box, Grid } from '@mui/material';
import Divider from '@mui/material/Divider';

import SequencingYieldPieChart from './SequencingYieldPieChart';

function DetailedSequencingYieldSummary(props) {
  const {
    analysis
  } = props;

if (!analysis) {
    return
}

  const isIllumina = analysis.attributes.raw_total_megabases_r2 !== null
 
  const totalMegabases = isIllumina ? 
    (analysis.attributes.raw_total_megabases + analysis.attributes.raw_total_megabases_r2) : 
    analysis.attributes.raw_total_megabases

  const downsampledMegabases = isIllumina ?
        ((analysis.attributes.qc_filtered_megabases + analysis.attributes.qc_filtered_megabases_r2) - (analysis.attributes.downsampled_megabases + analysis.attributes.downsampled_megabases_r2)) :
        (analysis.attributes.raw_total_megabases - analysis.attributes.downsampled_megabases)

  const downsampledReads = isIllumina ?
        (analysis.attributes.qc_filtered_reads - analysis.attributes.downsampled_reads) :
        (analysis.attributes.raw_total_reads - analysis.attributes.downsampled_reads)

  const qcFilteredMegabases = isIllumina ?
        ((analysis.attributes.raw_total_megabases + analysis.attributes.raw_total_megabases_r2) - (analysis.attributes.qc_filtered_megabases + analysis.attributes.qc_filtered_megabases_r2)) :
        (analysis.attributes.downsampled_megabases - analysis.attributes.qc_filtered_megabases)

  const qcFilteredReads = isIllumina ?
        (analysis.attributes.raw_total_reads - analysis.attributes.qc_filtered_reads) :
        (analysis.attributes.downsampled_reads - analysis.attributes.qc_filtered_reads)

  const humanReadsRemovedMegabases = isIllumina ?

        ((analysis.attributes.downsampled_megabases + analysis.attributes.downsampled_megabases_r2) - (analysis.attributes.human_reads_removed_megabases + analysis.attributes.human_reads_removed_megabases_r2)) :
        (analysis.attributes.qc_filtered_megabases - analysis.attributes.human_reads_removed_megabases)

  const humanReadsRemovedReads = isIllumina ?
        (analysis.attributes.downsampled_reads - analysis.attributes.human_reads_removed_reads) :
        (analysis.attributes.qc_filtered_reads - analysis.attributes.human_reads_removed_reads)

  const analyzedMegabases = isIllumina ? 
        (analysis.attributes.human_reads_removed_megabases + analysis.attributes.human_reads_removed_megabases_r2) :
        analysis.attributes.human_reads_removed_megabases
        
  return (
    <>
      <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
        <Grid container>
          <Grid item xs={12}>
            <p><b>SEQUENCING YIELD</b></p>
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: '35px 40px' }}>
            <p>
              <b>Total Data:</b>
              {' '}
              {totalMegabases.toLocaleString() || '-'} MBs ({analysis?.attributes.raw_total_reads.toLocaleString() || '-'} read{isIllumina ? ' pairs' : 's'})
            </p>
            <Divider />
            <p style={{color: "gray", marginLeft: "40px"}}>
              <b>Removed by Downsampling:</b>
              {' '}
              {downsampledMegabases.toLocaleString()} MBs ({downsampledReads.toLocaleString()} read{isIllumina ? ' pairs' : 's'})
            </p>
            <p style={{color: "#FF5326", marginLeft: "40px"}}>
              <b>Removed as Low Quality:</b>
              {' '}
              {qcFilteredMegabases.toLocaleString()} MBs ({qcFilteredReads.toLocaleString()} read{isIllumina ? ' pairs' : 's'})
            </p>
            <p style={{color: "#5267AA", marginLeft: "40px"}}>
              <b>Removed as Human:</b>
              {' '}
              {humanReadsRemovedMegabases.toLocaleString()} MBs ({humanReadsRemovedReads.toLocaleString()} read{isIllumina ? ' pairs' : 's'})
            </p>
            <Divider />
            <p style={{color: "green"}}>
              <b>Analyzed Data:
              {' '}
              {analyzedMegabases.toLocaleString() || '-'} MBs ({analysis?.attributes.human_reads_removed_reads.toLocaleString() || '-'} read{isIllumina ? ' pairs' : 's'})</b>
            </p>
          </Box>
            </Grid>
      <Grid item xs={12} sm={6} sx={{paddingRight: '90px', paddingBottom: '20px'}}>
        {analysis ? <SequencingYieldPieChart analysisAttributes={analysis.attributes} /> : null}
      </Grid>
        </Grid>
    </>
  );
}

export default DetailedSequencingYieldSummary;
