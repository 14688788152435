import React from 'react';

import { Box } from '@mui/material';

import ontLogo from '../ont_logo.svg';
import illuminaLogo from '../illumina_logo.svg';

function DetailedRunMetadataSummary(props) {
  const {
    run,
  } = props;

  return (
    <>
      <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
        <p><b>SEQUENCING</b></p>
      </Box>
      <Box sx={{ padding: '1px 10px' }}>
        <p>
          <b>Run / Dataset Name:</b>
          {' '}
          {run?.attributes.name}
        </p>
        <p>
          <b>Sequencer:</b>
          {' '}
          <img src={run && (run.attributes.sequencing_platform === 'illumina' || run.attributes.platform === 'illumina') ? illuminaLogo : ontLogo} alt="platform-logo" height="15px" style={{marginBottom: '-2px'}} />
          {' '}
          {run?.attributes.minion_sn || 'Unknown'}
        </p>
        <p>
          <b>Flow Cell:</b>
          {' '}
          {run?.attributes.flowcell_name || 'Unknown'}
          {' '}
          <span style={{color: 'gray'}}>{run && run.attributes.flowcell_sn ? `(${run.attributes.flowcell_sn})` : null}</span>
        </p>
        <p>
          <b>Library Prep Kit:</b>
          {' '}
          {run?.attributes.library_prep_kit || 'Unknown'}
        </p>
        <p>
          <b>Barcoding Kit:</b>
          {' '}
          {run?.attributes.barcoding_kit || 'Unknown'}
        </p>
        <p>
          <b>Date:</b>
          {' '}
          {run ? new Date(run.attributes.created_at).toDateString() : null}
        </p>
      </Box>
    </>
  );
}

export default DetailedRunMetadataSummary;
