import React from 'react';
import { Box, Grid } from '@mui/material';

function TestPanel() {
  return (
    <>
      <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
        <p><b>PANEL MENU</b></p>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ padding: '1px 10px' }}>
              <p><b>Gram Negative Bacteria:</b></p>
              <p style={{ paddingLeft: '10px' }}><i>Acinetobacter calcoaceticus/baumannii complex, Citrobacter freundii complex, Enterobacter cloacae complex, Escherichia coli, Klebsiella aerogenes, Klebsiella oxytoca, Klebsiella pneumoniae, Klebsiella quasipneumoniae, Klebsiella variicola, Proteus mirabilis, Pseudomonas aeruginosa group, Pseudomonas putida group, Serratia marcescens</i></p>

              <p><b>Gram Positive Bacteria:</b></p>
              <p style={{ paddingLeft: '10px' }}><i>Bacillus cereus group, Enterococcus faecalis, Enterococcus faecium, Staphylococcus aureus, Staphylococcus epidermidis, Staphylococcus lugdunensis, Streptococcus agalactiae, Streptococcus anginosus group, Streptococcus dysgalactiae group, Streptococcus mitis, Streptococcus mutans, Streptococcus oralis, Streptococcus pyogenes, Streptococcus sanguinis</i></p>
              <p><b>Fungi:</b></p>
              <p style={{ paddingLeft: '10px' }}><i>Candida albicans, Candida tropicalis, Nakaseomyces glabratus, Pichia kudriavzevii, [Candida] auris</i></p>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default TestPanel;
