import React from 'react';
import { Box, Grid } from '@mui/material';

function UHETestSummary() {
  return (
    <>
      <Box sx={{ backgroundColor: '#eee', padding: '1px 10px' }}>
        <p><b>UHE RUN DETAILS</b></p>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ padding: '1px 10px' }}>
              <p>
                <b>ID:</b>
                {' '}
                N/A
              </p>
              <p>
                <b>Assay version:</b>
                {' '}
                Blood2Bac v5.0
              </p>
              <p>
                <b>Load time:</b>
                {' '}
                N/A
              </p>
              <p>
                <b>Loading operator:</b>
                {' '}
                N/A
              </p>
              <p>
                <b>Primary cartridge lot:</b>
                {' '}
                N/A&emsp;
                <b>Expiry:</b>
                {' '}
                -
              </p>
              <p>
                <b>Transfer cartridge lot:</b>
                {' '}
                N/A&emsp;
                <b>Expiry:</b>
                {' '}
                -
              </p>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ padding: '1px 10px' }}>
              <p>
                <b>Software version:</b>
                {' '}
                N/A
              </p>
              <p>
                <b>Drawer:</b>
                {' '}
                N/A
              </p>
              <p>
                <b>End time:</b>
                {' '}
                N/A
              </p>
              <p>
                <b>Unloading operator:</b>
                {' '}
                N/A
              </p>
              <p>
                <b>Reagent cartridge lot:</b>
                {' '}
                N/A&emsp;
                <b>Expiry:</b>
                {' '}
                -
              </p>
              <p>
                <b>Transfer cartridge ID:</b>
                {' '}
                N/A
              </p>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default UHETestSummary;
