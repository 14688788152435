import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

function RunStatusSkeleton(props) {
    return (
        <Grid item xs={12} sm={4}>
            <Paper elevation={2}>
                <Box width="100%" height={157} sx={{padding: '10px'}}>
                    <Skeleton
                        variant="rounded"
                        width='90%'
                        height={30} 
                        sx={{marginBottom: '10px'}}
                    />
                    <Skeleton
                        variant="rounded"
                        width='60%'
                        height={30}
                        sx={{marginBottom: '10px'}}
                    />
                    <Skeleton
                        variant="rounded"
                        width='30%'
                        height={30}
                        sx={{marginBottom: '10px'}}
                    />
                </Box>
            </Paper>
        </Grid>
    )
}

export default RunStatusSkeleton;
