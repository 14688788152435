import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import QrCodeIcon from '@mui/icons-material/QrCode';

function SampleSearchAutocomplete(props) {
  const {
    activeProject,
    setSamples,
  } = props;

  const { getAccessTokenSilently } = useAuth0();

  const [searchableSamplesLoading, setSearchableSamplesLoading] = useState(false);
  const [searchableSamples, setSearchableSamples] = useState([]);
  const [searchableSamplesValue, setSearchableSamplesValue] = useState(null);
  const [searchableSamplesInputValue, setSearchableSamplesInputValue] = useState('');

  useEffect(() => {
    if (!activeProject) {
      return () => {};
    }

    if (searchableSamplesInputValue.length < 5) {
      setSearchableSamplesLoading(false);
      setSearchableSamples([]);
      return () => {};
    }

    setSearchableSamplesLoading(true);
    setSearchableSamples([]);

    const controller = new AbortController();
    const { signal } = controller;

    getAccessTokenSilently()
      .then(accessToken => {
          const headers = new Headers({
            Authorization: `Bearer ${accessToken}`,
          });

          return fetch(
            `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/projects/${activeProject.id}/lib_seqs/search?value=${searchableSamplesInputValue}`,
            {
              method: 'GET',
              headers,
              signal,
            },
          )
        })
        .then((res) => res.json())
        .then((response) => {
          setSearchableSamplesLoading(false);
          setSearchableSamples(response.data);
        })
        .catch((error) => console.error(error));

    return () => controller.abort();
  }, [getAccessTokenSilently, searchableSamplesInputValue, activeProject]);

  useEffect(() => {
    if (!searchableSamplesValue) {
      setSamples(null);
      return;
    }

    const filteredSearchableSamples = searchableSamples.filter(
      (item) => {
        if (!searchableSamplesValue) {
          return false;
        }

        if (searchableSamplesValue.startsWith('Starts with:')) {
          return item.attributes.sample_name.startsWith(searchableSamplesValue.replace('Starts with: ', ''));
        }

        return item.attributes.sample_name === searchableSamplesValue;
      },
    );

    setSamples(filteredSearchableSamples.sort((b, a) => b.id - a.id));
  }, [searchableSamplesValue, searchableSamples, setSamples]);

  useEffect(() => {
    setSamples(null);
    setSearchableSamplesLoading(false);
    setSearchableSamples([]);
    setSearchableSamplesValue(null);
    setSearchableSamplesInputValue('');
  }, [activeProject, setSamples]);

  let sampleSearchOptions = [];
  if ((searchableSamplesInputValue.length >= 5) && !searchableSamplesLoading) {
    const dedupedSortedSearchableSampleNames = [
      ...new Set(
        searchableSamples.sort((a, b) => b.id - a.id).map((item) => item.attributes.sample_name),
      ),
    ];

    if (dedupedSortedSearchableSampleNames.length <= 100) {
      sampleSearchOptions = [`Starts with: ${searchableSamplesInputValue}`, ...dedupedSortedSearchableSampleNames];
    } else {
      sampleSearchOptions = dedupedSortedSearchableSampleNames;
    }
  }

  const sampleSearchNoOptionsText = searchableSamplesInputValue.length >= 5
    ? 'No samples found'
    : 'Enter at least 5 characters';

  let autocompleteValue = searchableSamplesValue;
  if (searchableSamplesValue && (searchableSamplesValue.startsWith('Starts with:'))) {
    autocompleteValue = searchableSamplesValue.replace('Starts with: ', '');
  }

  return (
    <Autocomplete
      disablePortal
      filterOptions={(x) => x}
      id="sample-autocomplete"
      loading={searchableSamplesLoading}
      options={sampleSearchOptions}
      getOptionLabel={(option) => option}
      noOptionsText={sampleSearchNoOptionsText}
      onChange={(_, newValue) => setSearchableSamplesValue(newValue)}
      onInputChange={(_, newInputValue) => setSearchableSamplesInputValue(newInputValue)}
      sx={{ width: '100%' }}
      value={autocompleteValue}
      // eslint-disable-next-line react/jsx-props-no-spreading
      renderInput={(params) => {
        return (
          <TextField {...params} InputProps={{...params.InputProps, startAdornment: (
            <InputAdornment position="start">
              <QrCodeIcon />
            </InputAdornment>
          )}} label="Sample ID" />
        )
      }}
    />
  );
}

export default SampleSearchAutocomplete;